<template>
  <v-container fluid>
    Noch nicht implementiert, bitte Noten ggf. unter "Bei Wettkampf" korrigieren.
  </v-container>
</template>

<script>
export default {
  name: 'korrektur'
}
</script>

<style scoped>

</style>
